import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../../../../assets/images/logos/logo_new.png";
import logo2 from "../../../../assets/images/logos/logo2.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";

function HeaderHomeOne({t, handleTrans}) {
  const lang = useSelector((state) => state.lang.lang);
  useEffect(() => {
    StickyMenu(".main-header");
  });
  return (
    <>
      <MobileHeaderCom logo={logo2} translate={t} handleTrans={handleTrans} />
      <header className="main-header header-one">
        <div className="header-top bg-lighter py-6">
          <div className="top-left">
            <ul className="">
              <li className="">
                <a href="callto:+77017354647">+77017354647</a>
              </li>
              <li className="">{t("location")}</li>
            </ul>
          </div>
          <div className="top-right">
          <div className="">
              <a href="mailto:info@idg.kz">info@idg.kz</a>
            </div>
            <div className="office-time pl-4">
              <i className="far fa-clock"></i>
              <span>09:00  - 18:00 </span>
            </div>
            
          </div>
        </div>
        <div className="header-upper bg-white">
          <div className="container-fluid clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer bg-blue py-10 pb-1 px-85">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" width={180} height={70}/>
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <Navigation className="nav-home-one" t={t}/>
                
                <div className="menu-btn d-flex align-items-center">
                  <div className="pr-4">
                    <a type="button" onClick={() => {handleTrans("kz")}}><span className={lang === "kz" ? "emoji-colored":"emoji-grey"} role="img" aria-label="kz">🇰🇿</span></a>
                    <a type="button" onClick={() => {handleTrans("ru")}}><span className={lang === "ru" ? "emoji-colored":"emoji-grey"} role="img" aria-label="ru">🇷🇺</span></a>
                    <a type="button" onClick={() => {handleTrans("en")}}><span className={lang === "en" ? "emoji-colored":"emoji-grey"} role="img" aria-label="eng">🇬🇧</span></a>
                    
                  </div>
                  <Link className="theme-btn" style={{ display: window.matchMedia('(max-width: 768px)').matches ? 'none' : '' }}
                    to="/contact"onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}>
                      {t("contactUs")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeOne;
