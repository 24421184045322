import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "ru",
};

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    langToggle: (state, action) => ({
      ...state,
      lang: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { langToggle } = langSlice.actions;

export default langSlice.reducer;
