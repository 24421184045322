import { Route, Routes } from "react-router-dom";

// import Contact from "./components/Contact/index";

// import FourZeroFour from "./components/FourZeroFour/index";

import HomeOne from "./components/HomeOne";


export default function Routers() {
  return (
    <Routes>
      <Route path="/" element={<HomeOne />} />
      <Route path="/*" element={<HomeOne />} />
    </Routes>
  );
}
