import React from "react";
import Background from "../../assets/images/counter/counter-bg.jpg";
import CounterUp from "../helpers/CounterUp";

function AboutSection({ className, funFact = true, t }) {
  return (
    <section
      className={`about-section bg-light-black pt-120 rpt-100 ${
        className || ""
      }`}
      id="about-section "
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-image-shape rmb-70 wow fadeInLeft delay-0-2s">
              <img
                src={require(`../../assets/images/about/locomotive.png`).default}
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content text-white pr-70 rpr-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title"></span>
                <h2>
                {t('locomotiveSectionTitle')}
                </h2>
              </div>
              <p>
                {t('locomotiveSectionDescription')}
              </p>
              <ul className="list-style-one mt-15">
                <li>{t('locomotiveSectionFeature1')}</li>
                <li>{t('locomotiveSectionFeature2')}</li>
                <li>{t('locomotiveSectionFeature3')}</li>
                <li>{t('locomotiveSectionFeature4')}</li>
                <li>{t('locomotiveSectionFeature5')}</li>
                <li>{t('locomotiveSectionFeature6')}</li>
              </ul>
            </div>
          </div>
        </div>
        {funFact && (
          <div
            className="fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-2s">
                  <span
                    className="count-text"
                    data-speed="5000"
                    data-stop="520"
                  >
                    <CounterUp endValue={10} sectionSelect="about-section " />
                  </span>
                  <p>{t('counterSectionYears')}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-4s">
                  <span className="count-text plus">
                    <CounterUp endValue={50} sectionSelect="about-section " />
                  </span>
                  <p>{t('counterSectionRTU')}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={40} sectionSelect="about-section " />
                  </span>
                  <p>{t('counterSectionLocomotive')}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-8s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="352"
                  >
                    <CounterUp endValue={10} sectionSelect="about-section " />
                  </span>
                  <p>{t('counterSectionYearsOfUse')}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AboutSection;
