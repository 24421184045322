import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { langToggle } from '../../store/langSetting'
import BacktoTopCom from '../helpers/BackToTopCom'
import FooterHomeOne from '../partials/Footers/FooterHomeOne'
import HeaderHomeOne from '../partials/Headers/HeaderHomeOne/index'
import AboutSection from './AboutSection'
import FeatureSection from './FeatureSection'
// import GallerySection from "./GallerySection";
import Hero from './Hero'
import ServiceSection from './ServiceSection'
import SponserSection from './SponserSection'
import WorkProcessSection from './WorkProcessSection'

function HomeOne() {
	const dispatch = useDispatch()
	const scrollRef = useRef()
	const { t, i18n } = useTranslation()
	const handleTrans = code => {
		i18n.changeLanguage(code)
		dispatch(langToggle(code))
	}
	const scrollToRef = () => {
		window.scrollTo({
			top: scrollRef.current.offsetTop,
			behavior: 'smooth',
		})
	}
	return (
		<div style={{ overflowX: 'hidden' }}>
			<HeaderHomeOne t={t} handleTrans={handleTrans} />
			<Hero t={t} onClick={scrollToRef} />
			<ServiceSection t={t} />
			<AboutSection t={t} />
			<FeatureSection t={t} scrollRef={scrollRef} />
			<SponserSection />
			<WorkProcessSection t={t} />
			{/* <GallerySection /> */}
			<FooterHomeOne t={t} />
			<BacktoTopCom />
		</div>
	)
}

export default HomeOne
