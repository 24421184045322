import logo from "../../../../assets/images/logos/logo.png";

function FooterHomeOne({t}) {
  return (
    <footer className="main-footer footer-one text-white">
      <div className="footer-widget-area bgs-cover pt-60 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="footer-widget about-widget">
                <div className="footer-logo mb-35">
                  <a href="/">
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
                <div className="text">
                  {t('footerTagLine')}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="footer-widget contact-widget mr-30 rmr-0">
                <h4 className="footer-title">{t('contact')}</h4>
                <ul className="list-style-two">
                  <li>
                    <i className="fas fa-map-marker-alt"></i> {t('location')}
                  </li>
                  <li>
                    <i className="fas fa-clock"></i> {t('workDays')} 9:00 - 18:00
                  </li>
                  <li>
                    <i className="fas fa-phone-alt"></i>
                    <a href="callto:+77017354647">+77017354647</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:info@idg.kz">info@idg.kz</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area bg-blue">
        <div className="container">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a>ТОО "IDG Solutions"</a>
            </div>
            <p>2023 All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHomeOne;
