import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import shape from "../../assets/images/hero/hero-four-shape.png";
import background from "../../assets/images/hero/radio_1920_854.png";

export default function Hero({ onClick, t }) {
  const form = useRef();
  const [formPressed, setFormPressed] = useState(false);
  const [formSuccess, setFormSuccess] = useState(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const handleClick = () => {
    onClick();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(name && email && phone.length > 8){
      setFormPressed(true)
      emailjs.sendForm('service_iyhqxfs', 'template_tdappgz', form.current, 'FLUg8xS2H_yp_LcfV')
      .then((result) => { //eslint-disable-line
        setFormSuccess(true)
        setFormPressed(false)
      }, (error) => {
          console.log(error.text);
          setFormSuccess(false)
      });
    } 
  };
  const buttonText = formPressed //eslint-disable-line
  ? formSuccess === false
    ? t('error')
    : t('sent')
  : t('callMeBack');
  return (
    <section
      className="hero-section-three bgs-cover overlay pt-160 rpt-140 pb-80 rpb-100"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="hero-content text-white rmb-65">
              <span className="sub-title d-block wow fadeInUp delay-0-2s ml-2">
              {t("mainSubtitle")}
              </span>
              <h1 className="wow fadeInUp delay-0-4s mt-20">
              IDG SOLUTIONS
              </h1>
              <div className="hero-btns mt-35 wow fadeInUp delay-0-6s">
              <Link to="/contact" className="theme-btn-main mr-15 mb-10" style={{margin: 4}}>
                  {t("feedback")}
              </Link>
                <button type="button" onClick={handleClick} className="theme-btn style-five mb-10" style={{margin: 4}}>
                {t("aboutUs")}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="hero-section-form bg-white p-50 wow fadeInRight delay-0-4s mb-50">
              <div className="section-title">
                <h2 className="mb-0">{t("consulting")}</h2>
              </div>
              <p>{t("freeConsulting")}</p>
              <form ref={form}
                id="hero-form"
                className="hero-form mt-20"
                name="hero-form"
                action="#"
                method="post"
              >
                <div className="form-group">
                  <label htmlFor="name">{t("yourName")}</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className="form-control"
                    placeholder={t("surnamePlaceholder")}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="ivanov@gmail.com"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">{t('phoneNumber')}</label>
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    className="form-control"
                    placeholder="+77077777777"
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="service">Тема</label>
                  <select id="service" name="service">
                    <option value="">Перезвонить мне!</option>
                    <option value="">About</option>
                    <option value="">Contact</option>
                    <option value="">Team</option>
                  </select>
                </div> */ }
                
                 <button onClick={handleSubmit} style={{background: formSuccess?'green':""}} disabled={formPressed} type="submit" value="Send" className="theme-btn" >
                 {buttonText}
                </button> 
                
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-line-shape">
        <img src={shape} alt="Hero line shape" />
      </div>
    </section>
  );
}
