import { configureStore } from "@reduxjs/toolkit";
import rtlSetting from "./rtlSetting";
import langSetting from "./langSetting";

export default configureStore({
  reducer: {
    rtl: rtlSetting,
    lang: langSetting,
  },
});
