import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const languages = [
  { code: "en", native: "English" },
  { code: "ru", native: "Russian" },
];
i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
          contactUs: "Contact Us",
          contact: "Contacts",
          location: "Office 001, 46 G. Mustafin st., Astana, Kazakhstan, 010000",
          main: "Main",
          workDays:"Mon-Fri",
          workHours:"Work Hours",
          error:"Oops, Error!",
          sent:"Sending...!",

          mainSubtitle: "Communication Security Systems",
          feedback:"Feedback",
          aboutUs: "About Us",
          consulting: "Consultation",
          freeConsulting: "Get a free consultation right now!",
          yourName:"Your Name",
          surnamePlaceholder:"Ivanov I.I",
          phoneNumber:"Phone Number",
          callMeBack:"Call Me Back!",
          contactUs_2:"Contact Us",
          emailUS:"Email Us",


          radioRelayTitle: "Radio Relay Equipment",
          radioRelayMainText: "Universal high-speed multi-barrel multi-span radio relay communication lines",
          radioRelayBackTitle: "Provide remote objects with high-quality communication",
          radioRelayBackText: "We will help you select equipment that fully meets your requirements and put it into operation",

          locomotiveTitle: "Locomotive Radio Stations and Safety Systems",
          locomotiveMainText: "Quality communication and reliable safety in railway transportation",
          locomotiveBackTitle: "Add stability to the operation of your locomotive fleet",
          locomotiveBackText: "We will install, repair, or upgrade safety and radio communication systems for your locomotive fleet",

          xRayTitle: "X-Ray Scanners",
          xRayMainText: "Eliminate potential threats",
          xRayBackTitle: "Ensure a high level of security",
          xRayBackText: "We will help to choose X-Ray Scanners according to the tasks of your security division. Install them, and train security staff to work with X-Ray Scanners",

          UKVTitle: "VHF and HF Radio Communication Systems",
          UKVMainText: "Reliable communication anywhere, anytime",
          UKVBackTitle: "Take the interaction of your employees to a qualitatively new level",
          UKVBackText: "We will develop a project, implement it, and train personnel to improve the efficiency of your staff",



          locomotiveSectionTitle: "Reliable Service and Repair of Security and Radio Communication Systems for Your Locomotive Fleet",
          locomotiveSectionDescription: "Our engineers provide quality service and timely repair, ensuring the reliability of over 40 locomotives every month",
          locomotiveSectionFeature1: "CLUB-U",
          locomotiveSectionFeature2: "DVCD",
          locomotiveSectionFeature3: "CALS",
          locomotiveSectionFeature4: "RVS-1",
          locomotiveSectionFeature5: "42-RTM",
          locomotiveSectionFeature6: "RV-1.1M",


          counterSectionYears: "Years on the Market",
          counterSectionRTU: "X-Ray Scanners installed",
          counterSectionLocomotive: "Locomotives per Month",
          counterSectionYearsOfUse: "Years of Service Life of Installed X-Ray Scanners",
          

          aboutUsSectionTitle: "WHAT WE DO",
          aboutUsSectionDescription: "We ensure the protection of your investments in infrastructure through quality service",
          aboutUsSection_1_Title: "Technical Solution Development",
          aboutUsSection_1_Description: "Our engineers will develop a technical solution tailored to your specific needs",
          aboutUsSection_2_Title: "Supply, Installation, Commissioning",
          aboutUsSection_2_Description: "Our team will ensure timely delivery, flawless installation, and individual configuration of systems",
          aboutUsSection_3_Title: "Technical Maintenance",
          aboutUsSection_3_Description: "We provide quality maintenance for your systems, extending the lifespan of your investments",
          aboutUsSection_4_Title: "Repair and Spare Parts Supply",
          aboutUsSection_4_Description: "We offer fast repair services and supply original spare parts",
          aboutUsSection_5_Title: "Software Configuration",
          aboutUsSection_5_Description: "Our experts will configure your system to maximize its efficiency",
          aboutUsSection_6_Title: "Personnel Training",
          aboutUsSection_6_Description: "We provide high-quality and effective training for your staff",


          workflowSectionTitle: "Our Workflow",
          workflowSectionSubtitle_1: "Universal Solutions",
          workflowSectionSubtitle_2: "for Your Business",
          workflowSectionStep_1: "Communication with the Client",
          workflowSectionStep_2: "Evaluation and Planning of Work Scope",
          workflowSectionStep_3: "Successful Project Implementation",


          footerTagLine: "Stable communication and reliable security for comfortable operations",
          


          leaveMessage: "Leave a Message",
          sendMessage: "Send Message",
          yourMessage: "Your Message",
          pleaseMessage: "Please, send any question you have, and we will definitely call you back!",        
        
          footerAgitation: "We look forward to a productive collaboration with you!",
          footerAgitation2: "Join the ranks of our happy and successful clients!"
        }
      },
      ru: {
        translation: {
          contactUs: "Связаться С Нами",
          contact: "Контакты",
          location: "Астана, Г.Мустафина, дом 46, оф. 001",
          main: "Главная",
          workDays:"Пн-Пт",
          workHours:"Рабочие Часы",
          error:"Oops, Ошибка!",
          sent:"Отправляется...",

          mainSubtitle: "Системы Связи и Безопасности",
          feedback:"Обратная Связь",
          aboutUs: "О Нас",
          consulting: "Консультация",
          freeConsulting: "Получите бесплатную консультацию прямо сейчас!",
          yourName:"Ваше Имя",
          surnamePlaceholder:"Иванов И.И",
          phoneNumber:"Номер Телефона",
          callMeBack:"Перезвонить Мне!",
          contactUs_2:"Позвоните Нам",
          emailUS:"Напишите Нам",
          

          radioRelayTitle:"Радиорелейное оборудование",
          radioRelayMainText:"Универсальные высокоскоростные многоствольные многопролетные радиорелейные линии связи",
          radioRelayBackTitle:"Обеспечьте удаленные объекты связью высочайшего качества",
          radioRelayBackText:"Поможем подобрать оборудование полностью отвечающее Вашим требованиям и запустим его в эксплуатацию",

          locomotiveTitle:"Локомотивные радиостанции и Системы безопасности",
          locomotiveMainText:"Качественная связь и надежная безопасность на железнодорожном транспорте",
          locomotiveBackTitle:"Добавьте стабильности в работу своего локомотивного парка",
          locomotiveBackText:"Установим, отремонтируем Ваши или модернизируем на новые системы безопасности и радиосвязи Вашего локомотивного парка",

          xRayTitle:"Рентгентелевизионные установки (РТУ)",
          xRayMainText:"Избавьтесь от потенциальных угроз",
          xRayBackTitle:"Обеспечьте высокий уровень безопасности",
          xRayBackText:"Подберем РТУ под задачи Вашей службы досмотра, установим и обучим персонал",

          UKVTitle:"Системы УКВ и КВ радиосвязи",
          UKVMainText:"Надежная связь везде и всегда",
          UKVBackTitle:"Выведете взаимодействие Ваших сотрудников на качественно новый уровень",
          UKVBackText:"Разработаем проект, внедрим и обучим персонал для повышения эффективности работы Вашего персонала",


          locomotiveSectionTitle:"Надежный сервис и ремонт систем безопасности и радиосвязи для вашего локомотивного парка",
          locomotiveSectionDescription:"Наши инженеры обеспечивают качественный сервис и своевременный ремонт, обеспечивая надежность работы более 40 локомотивов каждый месяц",
          locomotiveSectionFeature1:"КЛУБ-У",
          locomotiveSectionFeature2:"УКБМ",
          locomotiveSectionFeature3:"АЛСН",
          locomotiveSectionFeature4:"РВС-1",
          locomotiveSectionFeature5:"42-РТМ",
          locomotiveSectionFeature6:"РВ-1.1М",
          
        
          counterSectionYears:"Лет На Рынке",
          counterSectionRTU:"РТУ Установлено",
          counterSectionLocomotive:"Локомотивов В Месяц",
          counterSectionYearsOfUse:"Срок Службы Установленных РТУ",



          aboutUsSectionTitle:"ЧТО МЫ ДЕЛАЕМ",
          aboutUsSectionDescription:"Обеспечиваем защиту Ваших инвестиций в инфраструктуру путем качественного сервиса",
          aboutUsSection_1_Title:"Разработка технического решения",
          aboutUsSection_1_Description:"Наши инженеры разработают техническое решение индивидуально под решения Ваших задач",
          aboutUsSection_2_Title:"Поставка, Монтаж, Пусконаладка",
          aboutUsSection_2_Description:"Наша команда обеспечит своевременную поставку систем, их безупречный монтаж и индивидуальную настройку",
          aboutUsSection_3_Title:"Техническое обслуживание",
          aboutUsSection_3_Description:"Мы проводим качественное ТО Ваших систем, продлевая жизнь Ваших инвестиций",
          aboutUsSection_4_Title:"Ремонт и поставка запасных частей",
          aboutUsSection_4_Description:"Мы осуществляем быстрый ремонт и поставку оригинальных запасных частей",
          aboutUsSection_5_Title:"Настройка программного обеспечения",
          aboutUsSection_5_Description:"Наши эксперты настроят Вашу систему для получения максимальной эффективности от её использования",
          aboutUsSection_6_Title:"Обучение Персонала",
          aboutUsSection_6_Description:"Мы предоставляем качественное и эффектиное обучение вашему персоналу",


          workflowSectionTitle:"Как Мы Работаем",
          workflowSectionSubtitle_1:"Универсальные Решения",
          workflowSectionSubtitle_2:"для Вашего Бизнеса",
          workflowSectionStep_1:"Связь с Заказчиком<",
          workflowSectionStep_2:"Оценка и Планирование Фронта Работы",
          workflowSectionStep_3:"Успешная Реализация Проекта",

          footerTagLine:"Стабильная связь и надёжная безопасность для комфортной работы",


          leaveMessage:"Оставьте сообщение",
          sendMessage:"Отправить сообщение",
          yourMessage:"Ваше сообщение",
          pleaseMessage:"Пожалуйста, отправьте любой интересующий Вас вопрос и мы Вам обязательно перезвоним!",

          footerAgitation:"Будем рады продуктивному сотрудничеству с Вами!",
          footerAgitation2:"Вступайте в ряды наших счастливых и успешных Клиентов!",
        }
      },
      kz: {
        translation: {
          contactUs: "Бізбен Хабарласу",
          contact: "Байланыс",
          location: "Астана, Ғ. Мустафина, 46-үй, 001 офис",
          main: "Басты Бет",
          workDays: "Дс-Жм",
          workHours: "Жұмыс Уақыты",
          error:"Oops, Қате!",
          sent:"Жіберіледі...",

          mainSubtitle: "Байланыс және Қауіпсіздік Жүйелері",
          feedback: "Кері Байланыс",
          aboutUs: "Біз Туралы",
          consulting: "Кеңес Беру",
          freeConsulting: "Қазіргі уақытта тегін кеңес алыңыз!",
          yourName: "Сіздің Атыңыз",
          surnamePlaceholder: "Иванов И.И",
          phoneNumber: "Телефон Нөмірі",
          callMeBack: "Маған Қайтадан Қоңырау Шалыңыз!",
          contactUs_2: "Бізге Хабарласыңыз",
          emailUS: "Бізге Хат Жазыңыз",

          radioRelayTitle: "Радиорелелік аспаптар",
          radioRelayMainText: "Универсалды жылдам көп аралықты көп ұңғылы радиорелелік байланыс тізбектері",
          radioRelayBackTitle: "Қашықтағы нысандарды  жоғары сапалы байланыспен қамтамасыз етіңіз",
          radioRelayBackText: "Сіздердің талаптарыңызға сай құрал-жабдықтарды таңдап , қолданысқа еңгіземіз",

          locomotiveTitle: "Локомотивтік радиостанциялар және қауіпсіздік жүйелері",
          locomotiveMainText: "Теміржол көліктерінде сапалы байланыс пен қауіпсіздікті қамтамасыз ету",
          locomotiveBackTitle: "Өзіңіздің локоматив паркіңіздің тұрақты жұмыс істеуін қамтамасыз етіңіз",
          locomotiveBackText: "Локомотивтік қорлардың қауіпсіздік және радиобайланыс жүйесін жаңартып, жаңа жүйелерге ауыстырып, қолданысқа еңгізуге көмек көрсетеміз",

          xRayTitle: "Рентгендік телевизиялық жасақтамалар (РТЖ)",
          xRayMainText: "Мүмкін болатын қауіптерден бас тартыңыз",
          xRayBackTitle: "Жоғары сапалы қауіпсіздік кепілдігін қамтамасыз етіңіз",
          xRayBackText: "Тексеру қызметіне сай РТЖ таңдауға, оны орнатуға және қызметкерлерді оқытуға көмек көрсетеміз",
          UKVTitle: "УКВ және КВ радиобайланыс жүйелері",

          UKVMainText: "Кез келген жерде және әр уақытта сенімді байланыс",
          UKVBackTitle: "Қызметкерлердің әрекеттесуін көрсету сапасын жаңа деңгейге шығарыңыз",
          UKVBackText: "Тапсырманың міндеттеріне сай, қызметкерлердің қабілеттілігін арттыру үшін жобалар жасап, қызметкерлерді оқытамыз, ",

          locomotiveSectionTitle: "Сіздің локомотивтік паркіңіздің сенімді қызметі және қауіпсіздік жүйелерін жөндеу , радиобайланыс орнату  ",
          locomotiveSectionDescription: "Біздің инженерлеріміз 40тан астам локомотив парктерін сапалы қызметпен және уақытылы жөндеу жұмыстарымен қамтамасыз етеді ",
          locomotiveSectionFeature1: "КЛУБ-У",
          locomotiveSectionFeature2: "УКБМ",
          locomotiveSectionFeature3: "АЛСН",
          locomotiveSectionFeature4: "РВС-1",
          locomotiveSectionFeature5: "42-РТМ",
          locomotiveSectionFeature6: "РВ-1.1М",


          counterSectionYears: "Жылдардан бері",
          counterSectionRTU: "РТЖ орнатылған",
          counterSectionLocomotive: "Локомотивтік парк айына",
          counterSectionYearsOfUse: "Орнатылған РТЖ пайдалану мерзімі",


          aboutUsSectionTitle: "БІЗ НЕ IСТЕЙМIЗ",
          aboutUsSectionDescription: "Инфрақұрылымдық инвестицияларыңыздың,  сапалы қызмет арқасында, қауіпсіздігін қамтамасыз етеміз  ",
          aboutUsSection_1_Title: "Техникалық шешімдерді дайындау",
          aboutUsSection_1_Description: "Біздің инженерлер сіздің міндеттеріңізге сай техникалық шешімдерді дайындайды",
          aboutUsSection_2_Title: "Жеткізу, орнату, іске асыру",
          aboutUsSection_2_Description: " Біздің команда жүйелерді уақтылы жеткізуді, оларды мінсіз орнатуды және теңшеуді қамтамасыз етеді ",
          aboutUsSection_3_Title: "Техникалық қызмет",
          aboutUsSection_3_Description: " Біз сіздің инвестицияларыңыздың өмірін ұзарта отырып, сіздің жүйелеріңіздің сапасын арттырамыз ",
          aboutUsSection_4_Title: " Қосалқы бөлшектерді жөндеу және жеткізу ",
          aboutUsSection_4_Description: " Біз бастапқы қосалқы бөлшектерді жылдам жөндеуді және жеткізуді жүзеге асырамыз ", 


          aboutUsSection_5_Title: " Бағдарламалық жасақтаманы орнату ",
          aboutUsSection_5_Description: " Біздің сарапшылар сіздің жүйеңізді оны пайдаланудан максималды тиімділік алу үшін конфигурациялайды ",
          aboutUsSection_6_Title: "Қызметкерлерді оқыту",
          aboutUsSection_6_Description: " Біз сіздің қызметкерлеріңізге сапалы және тиімді оқытуды қамтамасыз етеміз ",


          workflowSectionTitle: "Біз қалай жұмыс істейміз",
          workflowSectionSubtitle_1: "Сіздің бизнесіңізге",
          workflowSectionSubtitle_2: "арналған универсалды шешімдер",
          workflowSectionStep_1: "Тапсырысшымен байланысу",
          workflowSectionStep_2: "Бағалау және жұмыс бойынша жоспарлау",
          workflowSectionStep_3: "Сәтті жобалау",

          footerTagLine: " Ыңғайлы жұмыс үшін тұрақты байланыс және сенімді қауіпсіздік ",

          leaveMessage: "Хабарлама қалдыру",
          sendMessage: "Хабарлама жіберу",
          yourMessage: "Сіздің хабарламаңыз",
          pleaseMessage: "Сүзгіден отырмайтын сұрауыңызды жіберіңіз және біз сізге қайтадан хабарласамыз!",

          footerAgitation: " Сізді қызықтыратын кез-келген сұрақты жіберіңіз, біз сізге міндетті түрде қоңырау шаламыз!",
          footerAgitation2: " Біздің бақытты және табысты клиенттеріміздің қатарына қосылыңыз!",
        }
      }
    }
  });

export default i18n;