import React from "react";

function FeatureSection({ t, scrollRef }) {
  return (
    <section ref={scrollRef}  className="featured-section bgs-cover pt-240 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">{t('aboutUsSectionTitle')}</span>
              <h2>{t('aboutUsSectionDescription')}</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
        <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-analysis"></i>
              </div>
              <div className="feature-content " style={{height: '230px' }}>
                <h5 style={{height: '60px' }}className="">{t('aboutUsSection_2_Title')}</h5>
                <p>
                  {t('aboutUsSection_2_Description')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content" style={{height: '230px' }}>
                <h5 className="" style={{height: '60px' }}>{t('aboutUsSection_1_Title')}</h5>
                <p>
                  {t('aboutUsSection_1_Description')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="flaticon flaticon-development"></i>
              </div>
              <div className="feature-content" style={{height: '230px' }}>
                <h5 className="" style={{height: '60px' }}>{t('aboutUsSection_3_Title')}</h5>
                <p>
                  {t('aboutUsSection_3_Description')}
                </p>
              </div>
            </div>
          </div>
          
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-0s">
              <div className="icon">
                <i className="flaticon flaticon-development-2"></i>
              </div>
              <div className="feature-content" style={{height: '230px' }}>
                <h5 className="" style={{height: '60px' }}>{t('aboutUsSection_4_Title')}</h5>
                <p>
                  {t('aboutUsSection_4_Description')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-4s">
              <div className="icon">
                <i className="flaticon flaticon-cloud-computing-1"></i>
              </div>
              <div className="feature-content" style={{height: '230px' }}>
                <h5 style={{height: '60px' }}>{t('aboutUsSection_5_Title')}</h5>
                <p>
                {t('aboutUsSection_5_Description')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-6s">
              <div className="icon">
                <i className="flaticon flaticon-development-3"></i>
              </div>
              <div className="feature-content" style={{height: '230px' }}>
                <h5 className="" style={{height: '60px' }}>{t('aboutUsSection_6_Title')}</h5>
                <p>
                {t('aboutUsSection_6_Description')}
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
