import React from "react";

function ServiceSection({t}) {
  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-sm-6" >
            <div className="service-box wow fadeInUp delay-0-2s" >
              <div className="service-normal" style={{ display: 'flex', flexDirection: 'column', height: '450px' }}>
                <div className="icon mb-4" >
                <svg
                    viewBox="0 0 24 24"
                    fill="#104CB9"
                    height="4em"
                    strokeWidth={0.1}                  >
                    <path d="M12 10a2 2 0 012 2 2 2 0 01-.47 1.29L16.7 22h-2.13L12 14.93 9.43 22H7.3l3.17-8.71A2 2 0 0110 12a2 2 0 012-2m0-2a4 4 0 00-4 4c0 .5.1 1 .28 1.46l-.88 2.4A6.026 6.026 0 016 12a6 6 0 016-6 6 6 0 016 6c0 1.47-.53 2.81-1.4 3.86l-.88-2.4C15.9 13 16 12.5 16 12a4 4 0 00-4-4m0-4a8 8 0 00-8 8c0 2.36 1 4.5 2.64 5.94l-.72 2A10.005 10.005 0 012 12 10 10 0 0112 2a10 10 0 0110 10c0 3.23-1.54 6.11-3.92 7.94l-.72-2C19 16.5 20 14.36 20 12a8 8 0 00-8-8z" />
                  </svg>
                </div>
                <h6 className="mb-4">{t('radioRelayTitle')}</h6>
                <p>
                  {t('radioRelayMainText')}
                </p>
                <a className="btn-circle" style={{ marginTop: 'auto' }}>
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>{t('radioRelayBackTitle')}</h3>
                <p>{t('radioRelayBackText')}</p>
              </div>
              
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal" style={{ display: 'flex', flexDirection: 'column', height: '450px' }}>
                <div className="icon mb-1">
                  <svg
                    fill="#104CB9"
                    viewBox="0 0 24 24"
                    height="5em"
                    strokeWidth={0.1}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.621 1.485c1.815-.454 2.943-.454 4.758 0 .784.196 1.743.673 2.527 1.119.688.39 1.094 1.148 1.094 1.979V13.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5V4.583c0-.831.406-1.588 1.094-1.98.784-.445 1.744-.922 2.527-1.118zm5-.97C8.647.02 7.353.02 5.38.515c-.924.23-1.982.766-2.78 1.22C1.566 2.322 1 3.432 1 4.582V13.5A2.5 2.5 0 003.5 16h9a2.5 2.5 0 002.5-2.5V4.583c0-1.15-.565-2.26-1.6-2.849-.797-.453-1.855-.988-2.779-1.22zM5 13a1 1 0 11-2 0 1 1 0 012 0zm0 0a1 1 0 112 0 1 1 0 01-2 0zm7 1a1 1 0 10-1-1 1 1 0 10-2 0 1 1 0 002 0 1 1 0 001 1zM4.5 5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3V5h-3zm4 0v3h3a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-3zM3 5.5A1.5 1.5 0 014.5 4h7A1.5 1.5 0 0113 5.5v2A1.5 1.5 0 0111.5 9h-7A1.5 1.5 0 013 7.5v-2zM6.5 2a.5.5 0 000 1h3a.5.5 0 000-1h-3z"
                    />
                  </svg>
                </div>
                <h6 className="">
                  {t('locomotiveTitle')}
                </h6>
                <p className="pb-2">
                  {t('locomotiveMainText')}
                </p>
                <br></br>
                <a className="btn-circle" style={{ marginTop: 'auto' }}>
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>
                  {t('locomotiveBackTitle')}
                </h3>
                <p>
                  {t('locomotiveBackText')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal" style={{ display: 'flex', flexDirection: 'column', height: '450px' }}>
                <div className="icon mb-4">
                <svg
                  viewBox="0 0 24 24"
                  fill="#104CB9"
                  height="3.5em"
                >
                  <path d="M4 14.46a1 1 0 00-2 0v1a1 1 0 001 1h1a1 1 0 000-2zM8.18 4h2.1a1 1 0 000-2h-2.1a1 1 0 000 2zm6.28 0a1 1 0 002 0V3a1 1 0 00-1-1h-1a1 1 0 000 2zM4 2H3a1 1 0 00-1 1v1a1 1 0 002 0 1 1 0 000-2zm-1 9.28a1 1 0 001-1v-2.1a1 1 0 00-2 0v2.1a1 1 0 001 1zM15.82 20h-2.1a1 1 0 100 2h2.1a1 1 0 000-2zM21 7.54h-1a1 1 0 000 2 1 1 0 002 0v-1a1 1 0 00-1-1zm0 5.18a1 1 0 00-1 1v2.1a1 1 0 002 0v-2.1a1 1 0 00-1-1zm-4.54-5.18a1 1 0 10-2 0H8.54a1 1 0 00-1 1v5.92a1 1 0 100 2 1 1 0 002 0h5.92a1 1 0 001-1V9.54a1 1 0 100-2zm-2 6.92H9.54V9.54h4.92zM21 19a1 1 0 00-1 1 1 1 0 000 2h1a1 1 0 001-1v-1a1 1 0 00-1-1zM9.54 20a1 1 0 00-2 0v1a1 1 0 001 1h1a1 1 0 000-2z" />
                </svg>
                </div>
                <h6 className="pb-3">
                  {t('xRayTitle')}
                </h6>
                <p>
                  {t('xRayMainText')}
                </p>
                <a className="btn-circle" style={{ marginTop: 'auto' }}>
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>
                {t('xRayBackTitle')}
                </h3>
                <p>
                  {t('xRayBackText')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal" style={{ display: 'flex', flexDirection: 'column', height: '450px' }}>
                <div className="icon mb-4">
                <svg
                    viewBox="0 0 1024 1024"
                    fill="#104CB9"
                    height="3.5em"
                  >
                    <path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5 214 654.3V226.7l298-101.6 298 101.6v427.6zM402.9 528.8l-77.5 77.5a8.03 8.03 0 000 11.3l34 34c3.1 3.1 8.2 3.1 11.3 0l77.5-77.5c55.7 35.1 130.1 28.4 178.6-20.1 56.3-56.3 56.3-147.5 0-203.8-56.3-56.3-147.5-56.3-203.8 0-48.5 48.5-55.2 123-20.1 178.6zm65.4-133.3c31.3-31.3 82-31.3 113.2 0 31.3 31.3 31.3 82 0 113.2-31.3 31.3-82 31.3-113.2 0s-31.3-81.9 0-113.2z" />
                  </svg>
                </div>
                <h6 className="pb-3">
                  {t('UKVTitle')}
                </h6>
                <p>
                {t('UKVMainText')}
                </p>
                <a className="btn-circle" style={{ marginTop: 'auto' }}>
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>
                  {t('UKVBackTitle')}
                </h3>
                <p>
                  {t('UKVBackText')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
