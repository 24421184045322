import React from 'react'
import SliderCom from '../helpers/SliderCom'

function SponserSection() {
	const settings = {
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		speed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	}
	return (
		<div className='logo-section bg-lighter py-120 rpy-100'>
			<div className='container'>
				<div className='logo-carousel-wrap'>
					<SliderCom settings={settings}>
						<div className='logo-item'>
							<a href='https://www.micran.ru/'>
								<img
									src={
										require(`../../assets/images/client-logo/mikran.png`)
											.default
									}
									alt='Client Logo'
								/>
							</a>
						</div>
						<div className='logo-item'>
							<a href='https://aviatnetworks.com/'>
								<img
									src={
										require(`../../assets/images/client-logo/aviat.png`).default
									}
									alt='Client Logo'
								/>
							</a>
						</div>
						<div className='logo-item'>
							<a href='https://advin.by/'>
								<img
									src={
										require(`../../assets/images/client-logo/advin.png`).default
									}
									alt='Client Logo'
								/>
							</a>
						</div>
						<div className='logo-item'>
							<a href='https://www.icomjapan.com/'>
								<img
									src={
										require(`../../assets/images/client-logo/icom.png`).default
									}
									alt='Client Logo'
								/>
							</a>
						</div>
					</SliderCom>
				</div>
			</div>
		</div>
	)
}

export default SponserSection
