import React, {useRef} from "react";
import { Link } from "react-router-dom";

function WorkProcessSection({t}) {
  const ref = useRef(null);
  return (
    <section className="work-progress-area bgs-cover py-120 rpy-100">
      <div ref={ref} className="container">
        <div className="work-progress-inner bg-blue text-white br-5 py-85 px-75">
          <div className="section-title-with-btn mb-10">
            <div className="section-title">
              <span className="sub-title">{t('workflowSectionTitle')}</span>
              <h2>
                <p>{t('workflowSectionSubtitle_1')}</p>
                <p>{t('workflowSectionSubtitle_2')}</p>
              </h2>
            </div>
            <Link  className="theme-btn style-two mt-15"
              to="/contact" onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}>
                {t('contactUs')}
            </Link>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-3 col-sm-6">
              <div className="work-progress-item wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon flaticon-speech-bubble"></i>
                  <span className="progress-step">1</span>
                </div>
                <h3>{t('workflowSectionStep_1')}</h3>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="work-progress-item wow fadeInUp delay-0-4s">
                <div className="icon">
                  <i className="flaticon flaticon-vector"></i>
                  <span className="progress-step">2</span>
                </div>
                <h3>{t('workflowSectionStep_2')}</h3>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="work-progress-item wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon flaticon-like-1"></i>
                  <span className="progress-step">3</span>
                </div>
                <h3>{t('workflowSectionStep_3')}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkProcessSection;
