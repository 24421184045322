import React, {useState} from 'react';
import useToggle from "../../../../hooks/useToggle";
import Drawer from "../../../helpers/Drawer";



export default function MobileHeaderCom(props) {
  const [lang, setLang] = useState("ru")
  const [drawer, setValue] = useToggle(false);
  const {translate, logo, handleTrans} = props;
  return (
    <div>
      <Drawer 
      drawer={drawer} 
      action={setValue.toggle} 
      t={translate} 
      />
      <div className={`mobile-header d-block d-lg-none `}>
        <div
          className="d-flex justify-content-between"
          style={{ alignItems: "center", height: "100%" }}
        >
          <a href="/" className="logo-area">
            <img src={logo} alt="" width={120}/>
          </a>
            <a type="button" onClick={() => {handleTrans("kz"); setLang("kz")}}><span className={lang === "kz" ? "emoji-colored-mobile":"emoji-grey-mobile"} role="img" aria-label="kz">🇰🇿</span></a>
            <a type="button" onClick={() => {handleTrans("ru"); setLang("ru")}}><span className={lang === "ru" ? "emoji-colored-mobile":"emoji-grey-mobile"} role="img" aria-label="ru">🇷🇺</span></a>
            <a type="button" onClick={() => {handleTrans("en"); setLang("en")}}><span className={lang === "en" ? "emoji-colored-mobile":"emoji-grey-mobile"} role="img" aria-label="eng">🇬🇧</span></a>
          <div className="button-area">
            <span onClick={setValue.toggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "30px", height: "30px" }}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

